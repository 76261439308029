($ => {
	// lazy loading handling wpfacet on scroll
	const isElementInViewport = el => {
		// Special bonus for those using jQuery

		// eslint-disable-next-line no-undef
		if (typeof jQuery === 'function' && el instanceof jQuery) {
			el = el[0];
		}

		const rect = el.getBoundingClientRect();

		return (
			rect.top >= 0 &&
			rect.left >= 0 &&
			rect.bottom <=
				(window.innerHeight ||
					document.documentElement.clientHeight) /* or $(window).height() */ &&
			rect.right <=
				(window.innerWidth ||
					document.documentElement.clientWidth) /* or $(window).width() */
		);
	};

	let $loadMoreButton = null;

	$(document).on('facetwp-loaded', function() {
		$loadMoreButton = $('.facetwp-load-more');
	});

	document.addEventListener(
		'scroll',
		() => {
			if (!!$loadMoreButton && isElementInViewport($loadMoreButton)) {
				// eslint-disable-next-line no-undef
				if (FWP.is_load_more === false && $loadMoreButton.css('display') !== 'none') {
					$loadMoreButton.trigger('click');
				}
			}
		},
		{passive: true},
	);
})(window.jQuery);

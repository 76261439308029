(function($) {
	// define element to be animated
	const $root = $('html, body');

	$('a[href*=\'#\']').click(e => {
		const elToScroll = $(e.target.hash);
		if (elToScroll.length > 0) {
			e.preventDefault();
			$root.animate(
				{
					scrollTop: elToScroll.offset().top - $('.header').height() - 60,
				},
				400,
			);
		}
	});

	$(document).ready(() => {
		// store hash from url to a variable
		const target = window.location.hash;

		// check if hash is a valid anchor and if it exists on the page
		if (target && !target.match(/\/|\./)) {
			const ele = $(target);

			$(document).ready(() => {
				if (ele.length > 0) {
					$root.animate(
						{
							scrollTop: $(target).offset().top - $('.header').height() - 60,
						},
						400,
					);
				}
			});
		}
	});
}(window.jQuery));

/**
 * Aucor Navigation.js
 * -------------------
 *
 * Features:
 * - only adds classes, no show() or hide()
 * - timer for hover exit: better usability
 * - works with tabs: a11y
 * - desktop menu with touch: doubletap
 * - mobile menu with touch
 * - works at least with 3 levels (probably more)
 *
 */

import {
	disablePageScroll,
	enablePageScroll,
} from 'scroll-lock';
import debouncedResize from 'debounced-resize';
import A11yDialog from 'a11y-dialog';

/**
 * Dropdown menu
 *
 * Timer for hover exit: better usability
 * Works with keyboard: a11y
 * Desktop menu with touch: doubletap
 * Mobile menu with touch
 * Works at least with 3 levels (probably more)
 *
 * @param {Object} args
 */
const componentDropdownMenu = args => {
	// setup args
	const desktopMinWidth = args.desktopMinWidth; // match this to $breakpoint-desktop-menu SASS variable
	const menu = args.menu;
	let screenWidth;
	let hoverTimeout;

	// validate
	if (!menu) {
		console.error('Invalid drop-down menu');
		return;
	}

	/**
	 * Helper: Get all ancestors
	 *
	 * @param {Object} item
	 * @param {string} cssClass
	 */
	const getAllAncestors = (item, cssClass) => {
		const result = [];
		let ancestor = item.parentElement;
		while (ancestor && !ancestor.isEqualNode(menu)) {
			if (ancestor.classList.contains(cssClass)) {
				result.push(ancestor);
			}
			ancestor = ancestor.parentElement;
		}
		return result;
	};

	/**
	 * Helper: Get closest ancestor
	 *
	 * @param {Object} item
	 * @param {string} cssClass
	 */
	const getAncestor = (item, cssClass) => {
		const result = getAllAncestors(item, cssClass);
		if (result.length) {
			return result[0];
		}
		return null;
	};

	/**
	 * Helper: Is element inside menu
	 *
	 * @param {Object} el
	 */
	const isElementInsideMenu = el => {
		while ((el = el.parentElement) !== null) {
			if (el.nodeType !== Node.ELEMENT_NODE) {
				continue;
			}
			if (el.isEqualNode(menu)) {
				return true;
			}
		}
		return false;
	};

	/**
	 * Helper: Is desktop menu
	 *
	 * Checks if window is wider than set desktop limit.
	 *
	 * @return {boolean} bool is desktop width screen
	 */
	const isDesktopMenu = () => {
		screenWidth = Math.max(
			document.documentElement.clientWidth,
			window.innerWidth || 0,
		);
		if (screenWidth < desktopMinWidth) {
			return false;
		}
		return true;
	};

	const menuIndicator = () => {
		const menuList = document.querySelector('.main-navigation__list');
		const marker = document.querySelector('#marker');
		const firstLevelItems = document.querySelectorAll('.main-navigation__list > li');
		const activeItem = document.querySelector('.main-navigation__list > li.current-menu-item, .main-navigation__list > li.current-menu-ancestor, .main-navigation__list > li.is-active');

		function initialState() {
			if (activeItem) {
				const activeItemOffset = activeItem.offsetLeft;
				const activeItemWidth = activeItem.offsetWidth;

				if (activeItem.classList.contains('has-children')) {
					marker.style.left = `${ (activeItemOffset - 16) }px`;
					marker.style.width = `${ (activeItemWidth + 24) }px`;
				} else {
					marker.style.left = `${ (activeItemOffset - 16) }px`;
					marker.style.width = `${ (activeItemWidth + 32) }px`;
				}
			}
			marker.style.opacity = 1;
		}

		initialState();

		menuList.addEventListener('mouseleave', () => {
			if (!activeItem) {
				initialState();
				marker.style.opacity = 0;
			} else {
				setTimeout(() => {
					initialState();
				}, 500);
			}
		});

		function indicator(e) {
			if (e.classList.contains('has-children')) {
				marker.style.left = e.offsetLeft - 16 + 'px';
				marker.style.width = e.offsetWidth + 24 + 'px';
			} else {
				marker.style.left = e.offsetLeft - 16 + 'px';
				marker.style.width = e.offsetWidth + 32 + 'px';
			}
		}

		firstLevelItems.forEach(link => {
			link.addEventListener('mouseenter', e => {
				if (!activeItem) {
					marker.style.opacity = 1;
				}
				indicator(e.target);
			});
		});
	};

	const initIndicator = () => {
		if (isDesktopMenu()) {
			menuIndicator();
		}

		// check if isDesktopMenu has changed
		debouncedResize(() => {
			if (isDesktopMenu()) {
				menuIndicator();
			}
		},
		);
	};

	// check if all fonts are loaded
	document.fonts.ready.then(() => {
		setTimeout(() => {
			initIndicator();
		}, 100);
	});

	/**
	 * Helper: Show menu item children <li class="menu-item-has-children">
	 *
	 * @param {Object} li
	 */
	const activateMenuItem = li => {
		// validate
		if (!li) {
			return;
		}

		// actiate <ul>
		const ul = li.querySelector('.sub-menu');
		const button = li.querySelector('.js-menu-caret');
		if (ul) {
			// acticate <li>
			li.setAttribute('aria-expanded', 'true');
			button.setAttribute('aria-expanded', 'true');
			ul.setAttribute('aria-hidden', 'false');
			if (isDesktopMenu()) {
				window.jQuery(ul).slideDown(250);
			}
			// check that <ul> fits viewport
			if (
				ul.getBoundingClientRect().right >
				(window.innerWidth || document.documentElement.clientWidth)
			) {
				ul.classList.add('is-out-of-bounds');
			}
		}

		// activate parents <li> + <ul>
		const ancestors = getAllAncestors(li, 'menu-item-has-children');
		for (let i = 0; i < ancestors.length; i++) {
			activateMenuItem(ancestors[i]);
		}
	};

	/**
	 * Helper: Hide menu item children <li class="menu-item-has-children">
	 *
	 * @param {Object} li
	 */
	function deactivateMenuItem(li) {
		// validate
		if (!li) {
			return;
		}

		li.classList.remove('is-clicked');

		// deactivate <ul>
		const ul = li.querySelector('.sub-menu');
		const button = li.querySelector('.js-menu-caret');
		if (ul) {
			// deactivate <li>
			li.setAttribute('aria-expanded', 'false');
			button.setAttribute('aria-expanded', 'false');

			ul.setAttribute('aria-hidden', 'true');
			if (isDesktopMenu()) {
				window.jQuery(ul).slideUp(250);
			}
			ul.classList.remove('is-out-of-bounds');
		}

		// deactivate children <li> + <ul>
		const children = li.querySelectorAll('.menu-item-has-children');
		for (let i = 0; i < children.length; i++) {
			deactivateMenuItem(children[i]);
		}
	}

	/**
	 * Helper: Toggle menu item
	 *
	 * @param {Object} li
	 */
	const toggleMenuItem = li => {
		if (li) {
			if (li.getAttribute('aria-expanded') === 'false') {
				activateMenuItem(li);
			} else {
				deactivateMenuItem(li);
			}
		}
	};

	/**
	 * Helper: Reset all menu-items' state
	 */
	const resetMenuItems = () => {
		const itemsWithChildren = menu.querySelectorAll(
			'.menu-item-has-children',
		);
		for (let i = 0; i < itemsWithChildren.length; i++) {
			deactivateMenuItem(itemsWithChildren[i]);
			itemsWithChildren[i].classList.remove('is-tapped');
		}
	};

	/**
	 * Event: Handle mouse enter hover
	 *
	 * @param {Object} e
	 */
	const menuItemMouseEnter = e => {
		if (isDesktopMenu()) {
			// clear any previously set closing timeout
			clearTimeout(hoverTimeout);

			// deactivate all <li> that are not part of this DOM tree
			const ancestors = getAllAncestors(
				e.currentTarget,
				'menu-item-has-children',
			);
			const liWithChildren = menu.querySelectorAll('.menu-item-has-children');
			for (let j = 0; j < liWithChildren.length; j++) {
				if (
					liWithChildren[j] !== e.currentTarget &&
					ancestors.indexOf(liWithChildren[j]) === -1
				) {
					deactivateMenuItem(liWithChildren[j]);
				}
			}

			// activate hovered <li>
			activateMenuItem(e.currentTarget);
		}
	};

	/**
	 * Event: Handle mouseleave hover
	 *
	 * Close menu when hover timer has ended (only for desktop menus)
	 *
	 * @param {Object} e
	 */
	const menuItemMouseLeave = e => {
		// delay closing for more natural hover
		if (isDesktopMenu()) {
			hoverTimeout = setTimeout(
				function(li) {
					deactivateMenuItem(li);
				},
				200,
				e.currentTarget,
			);
		}
	};

	/**
	 * Event: Dropdown caret clicked
	 *
	 * Open sub-menu and change caret state
	 *
	 * @param {Object} e
	 */
	const caretClickEvent = e => {
		// activate or deactivate <li>
		toggleMenuItem(getAncestor(e.currentTarget, 'menu-item-has-children'));

		// don't trigger parent(s)
		e.stopPropagation();
	};

	/**
	 * Event: Click on empty link (a[href="#"])
	 *
	 * @param {Object} e
	 */
	const emptyLinkClickEvent = e => {
		// cancel default action
		e.preventDefault();

		// activate or deactivate <li>
		const li = getAncestor(e.currentTarget, 'menu-item-has-children');
		if (li) {
			if (li.classList.contains('is-clicked')) {
				deactivateMenuItem(li);
				li.classList.remove('is-clicked');
			} else {
				activateMenuItem(li);
				li.classList.add('is-clicked');
			}
		}
	};

	/**
	 * Event: Touch outside menu after menu has been opened with single tap
	 *
	 * Closes menus if touch is outside of menus
	 *
	 * @param {Object} e
	 */
	const outsideMenuTouchEvent = e => {
		// if the target of the tap isn't menu nor a descendant of menu
		if (isDesktopMenu() && !isElementInsideMenu(e.currentTarget)) {
			resetMenuItems();
		}

		// remove this event listener
		document.removeEventListener('ontouchstart', outsideMenuTouchEvent, false);
	};

	/**
	 * Event: Menu item parent a touched
	 *
	 * In desktop mode, open sub-menu with first click and navigate
	 * only when doubletapped
	 *
	 * @param {Object} e
	 */
	const menuItemParentLinkTouch = e => {
		if (!isDesktopMenu()) {
			return;
		}

		const li = getAncestor(e.currentTarget, 'menu-item-has-children');

		if (!li.classList.contains('is-tapped')) {
			// first tap: don't go to <a> yet
			e.preventDefault();

			// remove .tapped class and close all <li> that don't belong to this DOM tree
			const ancestors = getAllAncestors(
				e.currentTarget,
				'menu-item-has-children',
			);
			const liWithChildren = menu.querySelectorAll('.menu-item-has-children');
			for (let j = 0; j < liWithChildren.length; j++) {
				if (
					liWithChildren[j] !== e.currentTarget &&
					ancestors.indexOf(liWithChildren[j]) === -1
				) {
					liWithChildren[j].classList.remove('is-tapped');
					deactivateMenuItem(liWithChildren[j]);
				}
			}

			// add .tapped class and activate <li>
			li.classList.add('is-tapped');
			activateMenuItem(li);

			// add event listener to click outside menu
			document.addEventListener('touchstart', outsideMenuTouchEvent, {
				passive: true,
			});
		}
	};

	/**
	 * Init
	 */
	const initDropdownMenu = () => {
		// close all menus
		resetMenuItems();

		// setup hover hooks (menu-item)
		const menuItems = menu.querySelectorAll('.main-navigation__item--1st-level');
		for (let j = 0; j < menuItems.length; j++) {
			menuItems[j].addEventListener('mouseenter', menuItemMouseEnter);
			menuItems[j].addEventListener('mouseleave', menuItemMouseLeave);
		}

		// setup click hooks (carets)
		const menuCarets = menu.querySelectorAll('.js-menu-caret');
		for (let k = 0; k < menuCarets.length; k++) {
			menuCarets[k].addEventListener('click', caretClickEvent);
		}

		// setup click hooks (empty links)
		const emptyLinks = menu.querySelectorAll('a[href="#"]');
		for (let l = 0; l < emptyLinks.length; l++) {
			emptyLinks[l].addEventListener('click', emptyLinkClickEvent);
		}

		// setup touch hooks (parent menu-item a)
		const submenuParentLinks = menu.querySelectorAll(
			'.menu-item-has-children a',
		);
		for (let m = 0; m < submenuParentLinks.length; m++) {
			submenuParentLinks[m].addEventListener(
				'touchstart',
				menuItemParentLinkTouch,
				{passive: true},
			);
		}

		// open current-menu-item parent if inside mobile-menu
		const isInsideMobileMenuContainer = getAncestor(menu, 'main-navigation-wrapper');
		if (isInsideMobileMenuContainer && !isDesktopMenu()) {
			const currentMenuItem = menu.querySelector('.current-menu-item');
			if (currentMenuItem) {
				const currentMenuItemParents = getAllAncestors(
					currentMenuItem,
					'menu-item-has-children',
				);
				for (let n = 0; n < currentMenuItemParents.length; n++) {
					activateMenuItem(currentMenuItemParents[n]);
				}
			}
		}
	};
	initDropdownMenu();

	// get all first level menu items with submenus to set minwidth to the one from the widest
	const firstLevelMenuItems = menu.querySelectorAll('.main-navigation__item--1st-level.has-children');

	if (firstLevelMenuItems.length) {
		firstLevelMenuItems.forEach(item => {
			// get widest submenu width possible
			const subMenues = item.querySelectorAll('.sub-menu');
			let widestWidth = 0;

			if (subMenues.length) {
				let oldDisplay = null;

				subMenues.forEach(subMenu => {
					oldDisplay = subMenu.style.display;
					subMenu.style.display = 'block';
					const subMenuWidth = subMenu.offsetWidth;

					if (subMenuWidth > widestWidth) {
						widestWidth = subMenuWidth + 32;
					}
				});

				subMenues.forEach(subMenu => {
					subMenu.style.display = oldDisplay;
				});
			}

			if (widestWidth > 0) {
				const firstLevelSubMenu = item.querySelector('.sub-menu--1st-level');

				if (!!firstLevelSubMenu) {
					firstLevelSubMenu.style.minWidth = widestWidth + 'px';
				}
			}
		});
	}

	// make the call chainable
	return this;
};

/**
 * Init dropdown-menus
 */
const dropdownMenus = document.querySelectorAll('.js-navigation');
for (let i = 0; i < dropdownMenus.length; i++) {
	componentDropdownMenu({
		menu: dropdownMenus[i],
		desktopMinWidth: 1024,
	});
}

/**
 * Mobile menu
 *
 * @param {Object} args
 */
const componentMobileMenu = args => {
	// setup args
	const menu = args.menu;
	const site = args.site;
	const menuWrapper = args.menuWrapper;
	const navigationWrapper = args.navigationWrapper;
	const navigationOverlay = args.navigationOverlay;
	const toggles = args.toggles;

	// validate
	if (!menu || !site || !toggles.length || !menuWrapper) {
		console.error('Invalid mobile_menu args');
		return;
	}

	// setup a11y dialog
	const dialog = new A11yDialog(navigationWrapper, site);

	// tasks to open menu visually
	const openMenu = () => {
		setTimeout(() => {
			document.body.classList.add('is-active-menu');
			setTimeout(() => {
				document.querySelector('.meta-navigation').classList.add('is-active-meta');
				menuWrapper.classList.add('active');
				navigationOverlay.classList.add('active');
			}, 100);
			toggles.forEach(toggle => {
				toggle.classList.add('is-active');
				toggle.setAttribute('aria-expanded', 'true');
			});
		}, 250);

		disablePageScroll(document.body);
	};

	// tasks to close menu visually
	const closeMenu = () => {
		document.body.classList.add('is-closing-menu');
		setTimeout(() => {
			document.querySelector('.meta-navigation').classList.remove('is-active-meta');

			menuWrapper.classList.remove('active');
			navigationOverlay.classList.remove('active');
			setTimeout(() => {
				document.body.classList.remove('is-active-menu');
			}, 300);
			document.body.classList.remove('is-closing-menu');
			toggles.forEach(toggle => {
				toggle.classList.remove('is-active');
				toggle.setAttribute('aria-expanded', 'false');
			});
		}, 200);
		setTimeout(() => {
			enablePageScroll(document.body);
		}, 250);
	};

	// hooks
	// can use element and event parameters for both hooks
	dialog.on('show', () => {
		openMenu();
	});
	// can use element and event parameters for both hooks
	dialog.on('hide', () => {
		closeMenu();
	});

	// toggle click
	const handleToggle = () => {
		if (document.body.classList.contains('is-active-menu')) {
			dialog.hide();
		} else {
			dialog.show();
		}
	};

	// close on anchor link
	const anchorLinks = menu.querySelectorAll('a[href*="#"]');
	for (let i = 0; i < anchorLinks.length; i++) {
		if (anchorLinks[i].getAttribute('href') !== '#') {
			anchorLinks[i].addEventListener('click', () => {
				dialog.hide();
				enablePageScroll(document.body);
			});
		}
	}

	for (let i = 0; i < toggles.length; i++) {
		toggles[i].addEventListener('click', handleToggle, false);
	}

	// make the call chainable
	return this;
};

/**
 * Init mobile menu
 */
componentMobileMenu({
	menu: document.querySelector('.js-navigation'),
	navigationWrapper: document.querySelector('.header-navigation-wrapper'),
	menuWrapper: document.querySelector('.navigation-overlay'),
	navigationOverlay: document.querySelector('.navigation-overlay__overlay'),
	site: document.querySelector('.js-page'),
	toggles: document.querySelectorAll('.js-menu-toggle'),
});

import Swiper, {Pagination, Autoplay, EffectFade} from 'swiper';

// configure Swiper to use modules
Swiper.use([Pagination, Autoplay, EffectFade]);

// init Swiper:
const heroHeaderSlider = document.querySelectorAll('.hero-header__slider');
const pageHeaderSlider = document.querySelectorAll('.page-header__slider');

const defaultOptions = {
	loop: true,
	speed: 2000,
	autoplay: {
		delay: 5000,
		disableOnInteraction: false,
	},
	effect: 'fade',
	allowTouchMove: false,
	fadeEffect: {
		crossFade: false,
	},
	pagination: {
		el: '.swiper-pagination',
		clickable: true,
	},
};

if (heroHeaderSlider.length) {
	heroHeaderSlider.forEach(slider => {
		new Swiper(slider, {
			...defaultOptions,
		});
	});
}

if (pageHeaderSlider.length) {
	for (const slider of pageHeaderSlider) {
		new Swiper(slider, {
			...defaultOptions,
		});
	}
}

/* eslint-disable no-unused-vars */
// Bootstrap plugins
// import Alert from 'bootstrap/js/dist/alert';
// import Button from 'bootstrap/js/dist/button';
// import Carousal from 'bootstrap/js/dist/carousel';
import Collapse from 'bootstrap/js/dist/collapse';
// import Modal from 'bootstrap/js/dist/modal';
// import Offcanvas from 'bootstrap/js/dist/offcanvas';
// import Popover from 'bootstrap/js/dist/popover';
// import Scrollspy from 'bootstrap/js/dist/scrollspy';
// import Tab from 'bootstrap/js/dist/tab';
// import Toast from 'bootstrap/js/dist/toast';
// import Tooltip from 'bootstrap/js/dist/tooltip';

($ => {
	$(function() {
		if (location.hash !== null && location.hash !== '' && !location.hash.match(/\/|\./)) {
			$(location.hash + '.collapse').collapse('show');
		}
	});
})(window.jQuery);

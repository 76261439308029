import Masonry from 'masonry-layout';
import debouncedResize from 'debounced-resize';

const cardGrids = document.querySelectorAll('.news-teaser.is-style-cards, .news-archive .archive-grid');

cardGrids.forEach(grid => {
	const masonry = new Masonry(grid,
		{
			itemSelector: '.tease',
			columnWidth: '.tease',
			gutter: 32,
			percentPosition: true,
		},
	);

	debouncedResize(() => {
		masonry.layout();
	});

	document.addEventListener('facetwp-loaded', () => {
		masonry.reloadItems();
		masonry.layout();
	});
});

import Plyr from 'plyr';

const wpVideos = document.querySelectorAll('video:not(.is-initialized)')
const ytEmbeds = document.querySelectorAll('.is-provider-youtube > .wp-block-embed__wrapper:not(.is-initialized)')
const options = {
    controls: [
        'play-large',
        'play',
        'mute',
        'progress',
        'current-time',
        'fullscreen',
    ]
}

function makePlyr(video) {

    video.classList.add('is-initialized')
    new Plyr(video, options)
}

//  modify youtube embeds
for (const embed of ytEmbeds) {
    const mediaUrl = embed.querySelector('iframe').src

    embed.setAttribute('data-plyr-provider', 'youtube')
    embed.setAttribute('data-plyr-embed-id', mediaUrl)
}

for (const video of [...wpVideos, ...ytEmbeds]) {
    makePlyr(video)
}

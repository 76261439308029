// on dom ready

const updateScrollbarWidth = () => {
	const scrollbarWidth = window.innerWidth - document.body.clientWidth;

	document.body.style.setProperty('--scrollbarWidth', `${ scrollbarWidth }px`);
};

document.addEventListener('DOMContentLoaded', () => {
	updateScrollbarWidth();
});

window.addEventListener('resize', () => {
	updateScrollbarWidth();
}, {passive: true});

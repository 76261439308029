(($) => {
	const $languageSwitcher = $('.language-switcher');
	const $languageSwitcherList = $('.js-wpml-ls-sub-menu');
	const $languageSwitcherToggle = $languageSwitcher.find('.wpml-ls-item-toggle');
	const $window = $(window);

	// toggle language switcher list on scroll if it is visible
	$window.on('scroll', () => {
		// check if $languageSwitcherList has style visibility: visible
		if ($languageSwitcherList.css('visibility') === 'visible') {
			// change visibility to hidden
			$languageSwitcherList.css('visibility', 'hidden').toggle();

			if ($languageSwitcher.hasClass('open')) {
				$languageSwitcher.removeClass('open');
			} else {
				$languageSwitcher.addClass('open');
			}
		}
	});

	function toggleLanguageSwitcher(e) {
		e.preventDefault();
		$languageSwitcherList.fadeToggle();

		if ($languageSwitcher.hasClass('open')) {
			$languageSwitcher.removeClass('open');
		} else {
			$languageSwitcher.addClass('open');
		}
	}

	// on $languageSwitcherToggle click fade in or out $languageSwitcherList
	$languageSwitcherToggle.on('click', (e) => {
		toggleLanguageSwitcher(e);
	});

	$(document).on('click', (e) => {
		if ($languageSwitcher.hasClass('open')) {
			setTimeout(() => {
				$languageSwitcherList.css('visibility', 'visible');
			}, 10);
		}
	});
})(window.jQuery);

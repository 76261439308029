// manifest
import 'pwacompat/src/pwacompat';

// add better focus and input type detection on body element (for development)
// import 'what-input';

// components
import './components/aucorNavigation';
import './components/bootstrap';
import './components/news-masonry';
import './components/scrollBar';
import './components/swiper';
import './components/video';
import './components/scrollToAnchor';

// infinte scroll for facetwp
import './components/lazyLoading';
import './components/languageSwitcher';
